import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'gatsby';
import { PictureCard } from './pictureCard';
import classNames from 'classnames';
import { prependSlashIfAbsent } from '../../utils/url';

const useStyles = createUseStyles((theme) => ({
  paper: {
    borderRadius: 5,
    overflow: 'hidden',
    height: '100%'
  },
  link: {
    width: '100%',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      textDecoration: 'none',
      color: 'black'
    },
    '&:hover img': {
      transform: 'scale(1.15)'
    }
  },
  image: {},
  content: {
    overflow: 'hidden'
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    image: {
      height: '40vw'
    }
  }
}));

export const BlogPostTrailer = ({ slug, contentClass, ...other }) => {
  const classes = useStyles();
  return (
    <Link to={prependSlashIfAbsent(slug)} className={classes.link}>
      <PictureCard
        imageClass={classes.image}
        contentClass={classNames(classes.content, contentClass)}
        {...other}
      />
    </Link>
  );
};
