import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  paper: {
    background: 'white',
    boxShadow: theme.boxShadow,
    borderRadius: 5,
    overflow: 'hidden',
    height: '100%'
  }
}));

export function Paper({ className, as, ...otherProps }) {
  const classes = useStyles();
  return React.createElement(as, {
    className: `${classes.paper} ${className}`,
    ...otherProps
  });
}

Paper.defaultProps = {
  as: 'section'
};
