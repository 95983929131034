import React from 'react';
import { createUseStyles } from 'react-jss';
import { Paper } from './paper';
import Img from 'gatsby-image';
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  image: {
    objectFit: 'cover',
    flex: '1 0 0%',
    '& img': {
      transition: [['transform 2s'], '!important']
    }
  },
  content: {
    flex: '1.3 0 0',
    margin: '1.5rem'
  },
  header: {
    marginBottom: 'calc(var(--font-size-lg) / 2)'
  },
  heading: {
    marginBottom: 0,
    fontSize: 'var(--font-size-lg)',
    fontWeight: 600
  },
  subtitle: {
    color: theme.greyTypeColor,
    fontSize: 'var(--font-size-sm)'
  },
  textBox: {
    overflow: 'hidden'
  },
  text: {
    fontSize: 'var(--font-size-md)',
    fontWeight: 400
  },
  paper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export function PictureCard({
  children,
  coverImage,
  title,
  description,
  imageClass,
  imageStyle,
  contentClass,
  subtitle
}) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Img
        fluid={coverImage.childImageSharp.fluid}
        className={classNames(classes.image, imageClass)}
        imgStyle={imageStyle}
      />
      <div className={classNames(classes.content, contentClass)}>
        <header className={classes.header}>
          <h2 className={classes.heading}>{title}</h2>
          {subtitle ? <p className={classes.subtitle}>{subtitle}</p> : null}
        </header>
        <p className={classes.text}>{description}</p>
        {children}
      </div>
    </Paper>
  );
}
