import { graphql } from 'gatsby';
import React from 'react';
import { Layout } from '../components/layout/layout';
import { SEO } from '../components/layout/seo';
import { useConfig } from '../hooks/useConfig';
import { createUseStyles } from 'react-jss';
import { prependSlashIfAbsent } from '../utils/url';
import { SubpageHeader } from '../components/layout/subpageHeader';
import { BlogPostTrailer } from '../components/utility/blogPostTrailer';

const useStyles = createUseStyles((theme) => ({
  greyBox: {
    paddingTop: 'var(--size-4x)',
    paddingBottom: 'var(--size-4x)',
    ...theme.minSidePadding,
    backgroundColor: theme.lightGrey
  },
  grid: {
    maxWidth: 1200,
    margin: [[0, 'auto']],
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(350px, 1fr) )',
    gridAutoRows: '400px',
    gap: '1.2vmax'
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    grid: {
      maxWidth: 400,
      gridTemplateColumns: '1fr'
    }
  }
}));

export default function Blog(props) {
  const {
    blogSEO: { title, description, keywords }
  } = useConfig();
  const classes = useStyles();
  return (
    <Layout
      slug={props.location.pathname}
      header={<SubpageHeader title="Kategorie" />}
      maxWidth={1600}
    >
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={props.location.pathname}
      />
      <div className={classes.greyBox}>
        <div className={classes.grid}>
          {props.data.allMarkdownRemark.nodes.map((node) => (
            <BlogPostTrailer
              key={node.fields.slug}
              title={node.frontmatter.title}
              subtitle={`Opublikowano: ${node.frontmatter.date}`}
              description={node.frontmatter.description || node.excerpt}
              slug={prependSlashIfAbsent(node.fields.slug)}
              coverImage={node.frontmatter.coverImage}
              contentClass={classes.blogPostTrailerContent}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
          collection
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          coverImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
