import React from 'react';
import { createUseStyles } from 'react-jss';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  container: {
    position: 'relative'
  },
  image: {}
}));

export function Header(props) {
  const classes = useStyles(props);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "main-page/header.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={classes.container}>
      <Img
        className={classNames(classes.image, props.imageClass)}
        fluid={data.file.childImageSharp.fluid}
      />
      {props.children}
    </div>
  );
}
